/* eslint-disable @typescript-eslint/camelcase */
import {
  DetailInstantBookingListData,
  InstantBookingSession
} from "@/domain/entities/InstantBooking";
import { CreateInstantBookingSessionRequestInteface } from "../contracts/InstantBookingRequest";
import { InstantBookingRequestInterface } from "@/data/payload/contracts/InstantBookingRequest";

export class CreateInstantBookingSessionApiRequest
  implements CreateInstantBookingSessionRequestInteface {
  payloadData = new InstantBookingSession({
    posId: 0,
    posName: "",
    originCityId: "",
    originCityName: "",
    totalShipmentId: 0,
    stt: [] as DetailInstantBookingListData[]
  });
  constructor(fields?: Partial<InstantBookingSession>) {
    Object.assign(this.payloadData, fields);
  }

  public toJSON(): string {
    return JSON.stringify({
      pos_id: this.payloadData.posId,
      pos_name: this.payloadData.posName,
      origin_city_id: this.payloadData.originCityId,
      origin_city_name: this.payloadData.originCityName,
      total_shipment_id: this.payloadData.totalShipmentId,
      stt: this.payloadData.stt.length
        ? this.payloadData.stt.map((item: DetailInstantBookingListData) => ({
            shipment_id: item.ibdShipmentId,
            shipment_package_id: item.ibdShipmentPackageId,
            chargeable_weight: item.chargeableWeight
          }))
        : []
    });
  }
}

export class UpdateSessionInstantBookingApiRequest
  implements InstantBookingRequestInterface {
  instantBookingId = 0;
  isFinish = false;
  stt = [] as DetailInstantBookingListData[];

  constructor(fields?: Partial<UpdateSessionInstantBookingApiRequest>) {
    Object.assign(this, fields);
  }

  public toJSON(): string {
    return JSON.stringify({
      instant_booking_id: this.instantBookingId,
      is_finish: this.isFinish,
      stt: this.stt.map((e: DetailInstantBookingListData) => {
        return {
          shipment_id: e.ibdShipmentId,
          shipment_package_id: e.ibdShipmentPackageId,
          stt_id: e.ibdSttId,
          stt_no: e.ibdSttNo,
          error_reason: e.ibdErrorReason
        };
      })
    });
  }
}
