
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { ShipmentInstantBooking } from "@/data/payload/api/ShipmentBookingRequest";
import {
  ShipmentId,
  PrintDataEntitas,
  ShipmentIdList
} from "@/domain/entities/Booking";
import { DistrictClient } from "@/domain/entities/Location";
import { defineAsyncComponent } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";
import { printDoc, printDocMassive, autoPrint } from "./print";
import { DistrictClientRequest } from "@/data/payload/api/LocationRequest";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { InstantBookingController } from "@/app/ui/controllers/InstantBookingController";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";
import parseQRCode from "@/app/infrastructures/misc/common-library/ParseQRcode";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { AddBookingCorporate } from "@/data/payload/api/BookingRequest";
/* eslint-disable @typescript-eslint/camelcase */

const DetailLayout = defineAsyncComponent({
  loader: () => import("@/app/ui/layout/detail-layout.vue")
});

@Options({
  components: {
    DetailLayout
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.hasChanged) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      MainAppController.closeMessageModal();
      next();
    }
  }
})
export default class InstantBookingV2Page extends Vue {
  refs: any = {};
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs["op"].toggle(event);
  }
  get panelVisible() {
    return this.refs.op?.visible;
  }

  columns = [
    {
      name: "No.",
      styleHead: "w-16 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: ShipmentId, index: any) => {
        return `<div class='text-left'>${index + 1}</div>`;
      }
    },
    {
      name: "Shipment ID",
      styleHead: "w-40 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: ShipmentId) => {
        return `<div class='text-left'>
          ${item.shipmentAlgoId}
        </div>`;
      }
    },
    {
      name: "No. STT",
      styleHead: "w-48 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: ShipmentId) => {
        return `<div class='text-left'>
          ${item.sttNoInstantBooking ? item.sttNoInstantBooking : "-"}
        </div>`;
      }
    },
    {
      name: "Pengirim",
      styleHead: "w-48 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: ShipmentId) => {
        return `<div class='text-left'>
          <p class='font-semibold'>${ellipsisString(
            item.shipmentSenderName,
            15
          )}</p>
          ${item.senderPhoneInstantBooking}
        </div>`;
      }
    },
    {
      name: "Penerima",
      styleHead: "w-48 text-left whitespace-no-wrap",
      styleBody: " text-center",
      render: (item: ShipmentId) => {
        return `<div class='text-left'>
         <p class='font-semibold'> ${item.shipmentRecipientName}</p>
         ${item.recipientPhoneInstantBooking}
        </div>`;
      }
    },
    {
      name: "Alamat Penerima",
      styleHead: "w-56 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: ShipmentId) => {
        return `<div class='flex'>
          ${ellipsisString(item.shipmentRecipientAddress, 20)}
        </div>`;
      }
    },
    {
      name: "Total Koli",
      styleHead: "w-32 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: ShipmentId) => {
        return `<div class='text-center'>
          ${(item.STT && item.STT.sttPieces.length) ||
            item.shipmentGoodDetail.length}
        </div>`;
      }
    },
    {
      name: "Total Berat",
      styleHead: "w-32 text-left whitespace-no-wrap",
      styleBody: " text-red-lp-200 text-center",
      render: (item: ShipmentId) => {
        return `<div class='text-left'>
          ${item.shipmentChargeableWeight} Kg
        </div>`;
      }
    },
    {
      name: "Total Tarif",
      styleHead: "w-32 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<div class='text-left text-red-lp-100 font-semibold'>
          ${formatPriceRP(item.sttAmountInstantbooking) ?? "-"}
        </div>`;
      }
    },
    {
      name: "Alasan Gagal",
      styleHead: "w-50 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: ShipmentId) => {
        return `<div class='text-left'>
          ${item.reasonFailed}
        </div>`;
      }
    },
    {
      name: "Status",
      styleHead: "w-24 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: ShipmentId) => {
        return `
            <div class="flex">
          <div
            class="rounded-full px-2 py-0 text-center ${this.extractColor(
              item
            )}"
          ><span class="capitalize">${this.extractStatusLabel(
            item
          )}</span></div></div>`;
      }
    },
    {
      name: "Print Label",
      styleHead: "w-32 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: ShipmentId) => {
        return `
          <div class="flex">
            <div class="text-center ${this.convertColorPrintStatus(
              item.autoPrintStatus
            )}">
              <span class="capitalize">${item.autoPrintStatus}</span>
            </div>
          </div>`;
      }
    },
    {
      name: "Aksi",
      styleHead: "w-24 text-left",
      key: "button_column",
      styleButton: (item: ShipmentId) => {
        if (item.statusShipment === "success") {
          return {
            flat: true,
            compact: true,
            class: "w-full",
            icon: "printer-red",
            clickFunction: () => {
              this.singlePrint(item.sttIdInstantBooking);
            }
          };
        }
        return {
          flat: true,
          compact: true,
          class: "w-full",
          icon: "printer-gray"
        };
      }
    }
  ];

  get clientIdForCourierRecommendation() {
    return FlagsMedusa.client_id_courrier_recommendation_config.getValue();
  }

  singlePrint(sttId: number) {
    const printData: PrintDataEntitas = this.printDataMassive.find(
      (item: PrintDataEntitas) => item.sttId === sttId
    );
    printDoc(printData);
  }

  printThermal(type: string) {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Cetak Semua STT yang Sukses?",
        message: `STT dengan status  <strong>Diproses</strong> & <strong>Gagal</strong> tidak akan dicetak`,
        textCancel: "Cek Ulang",
        onClose: () => MainAppController.closeMessageModal(),
        textSuccess: "Cetak",
        onSubmit: () => this.directPrintThermal(type),
        image: "badge-confirmation-general"
      })
    );
  }

  directPrintThermal(type: string) {
    printDocMassive(type, this.printDataMassive);
    this.sttListAfterScaned = this.sttListAfterScaned.filter(
      (item: ShipmentId) => item.statusShipment === "inProgress"
    );
    this.printDataMassive = [];
    MainAppController.closeMessageModal();
  }

  printInvoice() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Cetak Semua STT yang Sukses?",
        message: `STT dengan status  <strong>Diproses</strong> & <strong>Gagal</strong> tidak akan dicetak`,
        textCancel: "Cek Ulang",
        onClose: () => MainAppController.closeMessageModal(),
        textSuccess: "Cetak",
        onSubmit: () => this.directPrintReceipt(),
        image: "badge-confirmation-general"
      })
    );
  }

  directPrintReceipt() {
    const sttList = this.printDataMassive.map((stt: any) => {
      return stt.sttNo;
    });
    window.open(`/shipment/print-receipt?q=${sttList}`);
    this.sttListAfterScaned = this.sttListAfterScaned.filter(
      (item: ShipmentId) => item.statusShipment === "inProgress"
    );
    this.printDataMassive = [];
    MainAppController.closeMessageModal();
  }

  errorScanShipment = "";
  isError = false;
  onCloseModalError() {
    this.isError = false;
  }

  extractColor(item: any) {
    const color: any = {
      success: "bg-green-lp-300 text-green-lp-200",
      inProgress: "bg-yellow-lp-200 text-yellow-lp-100",
      failed: "bg-red-300 text-red-lp-200"
    };

    return (
      color[item.statusShipment.toLowerCase()] ??
      "bg-yellow-lp-100 text-yellow-lp-200"
    );
  }

  extractStatusLabel(item: any) {
    const label: any = {
      success: "Sukses",
      inProgress: "Diproses",
      failed: "Gagal"
    };

    return label[item.statusShipment.toLowerCase()] ?? "Diproses";
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  form = {
    sourceScaned: "",
    shipmentId: ""
  };
  partnerName = `${this.dataProfile.account_type_detail.company_name}`;
  origin = `${this.dataProfile.account_location?.city_code} - ${this.dataProfile.account_location?.city_name}`;
  get originDistrict() {
    const detail = LocationController.districtDetail;
    return {
      id: detail.id,
      name: `${detail.name}, ${detail.cityName}`,
      code: `${detail.code}`,
      cityCode: `${detail.cityCode}`,
      ursaCode: detail.ursaCode,
      cityId: detail.cityId,
      cityName: detail.cityName
    };
  }

  onConvertShipment = (value: string | number | null) => {
    if (String(value).includes("#")) {
      const val = String(value).split("#")[0];
      this.form.sourceScaned = val;
      return val;
    }
    this.form.sourceScaned = parseQRCode(value);
    return value;
  };

  mappingSttPieces(shipment: any) {
    return shipment.shipmentGoodDetail.length
      ? shipment.shipmentGoodDetail.map((pieces: any) => ({
          stt_piece_length: pieces.shipment_goods_length,
          stt_piece_width: pieces.shipment_goods_width,
          stt_piece_height: pieces.shipment_goods_height,
          stt_piece_gross_weight: pieces.shipment_goods_weight
        }))
      : [];
  }

  handleError() {
    BookingController.setError(false);
  }

  async bookingShipmentCorporate(shipment: any, unixId: number) {
    await BookingController.addBookingCorporateInstantBooking(new AddBookingCorporate({
      sttClient: Number(shipment.shipmentSttClientId),
      sttNo: "",
      sttShipmentId: shipment.shipmentAlgoId,
      sttNoRefExternal: shipment.shipmentAlgoId,
      sttTaxNumber: "",
      sttGoodsEstimatePrice: shipment.shipmentGoodsPriceEstimation,
      sttGoodsStatus: shipment.shipmentGoodsStatus,
      sttOriginCityId: this.originDistrict.cityCode,
      sttOriginDistrictId: this.originDistrict.code,
      sttDestinationCityId: shipment.shipmentDestinationDistrict?.cityCode,
      sttDestinationDistrictId: shipment.shipmentDestinationDistrict?.code,
      sttSenderName: shipment.shipmentSenderName,
      sttSenderPhone: shipment.senderPhoneInstantBooking,
      sttSenderAddress: shipment.shipmentSenderAddress,
      sttRecipientName: shipment.shipmentRecipientName,
      sttRecipientAddress: shipment.shipmentRecipientAddress,
      sttRecipientAddressType: "home",
      sttRecipientPhone: shipment.recipientPhoneInstantBooking,
      sttInsuranceType: shipment.shipmentInsurance,
      sttProductType: shipment.shipmentProduct,
      sttCommodityCode: "GEN",
      sttClientSttId: Number(shipment.shipmentSttClientId),
      sttVendorSttId: 0,
      sttIsCod: false,
      sttIsWoodpacking: shipment.shipmentIsWoodpacking,
      sttPieces: this.mappingSttPieces(shipment),
      sttPiecePerPack: 0,
      sttNextCommodity: "",
      sttCodAmount: 0,
      postalCodeDestination: shipment.postalCodeDestination,
      shipmentPackageId: shipment.shipmentPacketId,
      otherShipperTicketCode: shipment.dataRaw?.other_shipper_ticket_code
    }))
      .then((res: ResponsePayload) => {
        console.log(2222);
        this.printProcess(res.sttId, unixId);
      })
      .catch((error: any) => {
        this.errorHandling(error, unixId);
      });
  }

  async addInstantBooking(shipment: ShipmentId, unixId: number) {
    await ShipmentBookingController.addInstantBooking(
      new ShipmentInstantBooking({
        shipmentPackageId: shipment.shipmentPacketId,
        stt: {
          sttNo: "",
          sttShipmentId: shipment.shipmentAlgoId,
          sttTaxNumber: "",
          sttGoodsEstimatePrice: shipment.shipmentGoodsPriceEstimation,
          sttGoodsStatus: shipment.shipmentGoodsStatus,
          sttNoRefExternal: "",
          sttOriginCityId: this.originDistrict.cityCode,
          sttDestinationCityId: shipment.shipmentDestinationCity?.code,
          sttOriginDistrictId: this.originDistrict.code,
          sttDestinationDistrictId: shipment.shipmentDestinationDistrict?.code,
          sttSenderName: shipment.shipmentSenderName,
          sttSenderPhone: shipment.senderPhoneInstantBooking,
          sttSenderAddress: shipment.shipmentSenderAddress,
          sttRecipientName: shipment.shipmentRecipientName,
          sttRecipientPhone: shipment.recipientPhoneInstantBooking,
          sttRecipientAddress: shipment.shipmentRecipientAddress,
          sttProductType: shipment.shipmentProduct,
          sttCommodityCode: shipment.shipmentCommodityCode || "GEN",
          sttInsuranceType: shipment.shipmentInsurance,
          sttIsDo: shipment.isDO,
          sttIsCod: shipment.shipmentIsCod,
          sttIsDfod: shipment.shipmentIsDfod,
          sttCodFee: shipment.codFee,
          sttCodAmount: shipment.codAmount,
          sttPieces: this.mappingSttPieces(shipment),
          postalCodeDestination: shipment.postalCodeDestination,
          addressReceiverType: "home",
          sttWoodpacking: shipment.shipmentIsWoodpacking
        },
        districtOrigin: {
          id: this.originDistrict.id,
          code: this.originDistrict.code,
          name: this.originDistrict.name,
          ursaCode: this.originDistrict.ursaCode,
          city: {
            id: this.originDistrict.cityId,
            code: this.originDistrict.cityCode,
            name: this.originDistrict.cityName
          }
        },
        districtDestination: {
          id: shipment.shipmentDestinationDistrict.id,
          code: shipment.shipmentDestinationDistrict.code,
          name: shipment.shipmentDestinationDistrict.name,
          ursaCode: shipment.shipmentDestinationDistrict.ursa_code,
          city: {
            id: shipment.shipmentDestinationCity.id,
            code: shipment.shipmentDestinationCity.code,
            name: shipment.shipmentDestinationCity.name
          }
        },
        commodity: {
          commodityId: shipment.shipmentCommodityId,
          commodityCode: shipment.shipmentCommodityCode,
          commodityName: shipment.shipmentCommodityName,
          commodityDocumentSurcharge:
            shipment.shipmentCommodityDocumentSurcharge,
          hsCode: shipment.shipmentCommodityHsCode
        },
        partner: {
          id: this.dataProfile.account_type_detail?.id,
          name: this.dataProfile.account_type_detail?.company_name,
          code: this.dataProfile.account_type_detail?.code,
          partnerExternalCode: this.dataProfile.account_type_detail
            ?.partnerExternalCode,
          partnerLocation: {
            id: this.dataProfile.account_type_detail?.partnerLocation?.id,
            districtCode: this.dataProfile.account_type_detail?.partnerLocation
              ?.district_code
          },
          partnerLocationList: []
        }
      })
    )
      .then((res: ResponsePayload) => {
        this.printProcess(res.sttId, unixId);
      })
      .catch((error: any) => {
        this.errorHandling(error, unixId);
      });
  }

  errorHandling(error: any, unixId: number) {
    const getIndex = this.sttListAfterScaned.findIndex(
      (item: ShipmentId) => item.unixInstantBookId === unixId
    );
    this.sttListAfterScaned[getIndex].statusShipment = "failed";
    this.sttListAfterScaned[getIndex].reasonFailed =
      error?.response?.data?.message?.id;
  }

  printDataMassive: Array<any> = [];
  async printProcess(sttId: number, unixId: number) {
    const print = await BookingController.generatePrinDataBookingInstant({
      stt: sttId,
      accountType:
        AccountController.accountData.account_type === "partner"
          ? AccountController.accountData.account_type_detail.type
          : AccountController.accountData.account_type
    });

    if (print) {
      const getIndex = this.sttListAfterScaned.findIndex(
        (item: ShipmentId) => item.unixInstantBookId === unixId
      );
      this.printDataMassive.push(BookingController.generatePrintData);
      this.sttListAfterScaned[getIndex].sttIdInstantBooking =
        BookingController.generatePrintData.sttId;
      this.sttListAfterScaned[getIndex].sttNoInstantBooking =
        BookingController.generatePrintData.sttNo;
      this.sttListAfterScaned[getIndex].sttAmountInstantbooking =
        BookingController.generatePrintData.totalTariff;
      this.sttListAfterScaned[getIndex].statusShipment = "success";
      const printResult = await autoPrint(
        BookingController.generatePrintData,
        this.$i18n.locale
      );
      if (printResult) {
        this.sttListAfterScaned[getIndex].autoPrintStatus = "Sukses";
      } else {
        this.sttListAfterScaned[getIndex].autoPrintStatus = "Gagal Print";
      }
    } else {
      const getIndex = this.sttListAfterScaned.findIndex(
        (item: ShipmentId) => item.unixInstantBookId === unixId
      );
      this.sttListAfterScaned[getIndex].statusShipment = "failed";
      this.sttListAfterScaned[getIndex].reasonFailed =
        "Gagal mendapatkan data resi";
    }
  }

  setDistrict(district: any, index: number) {
    const shipmentData = BookingController.shipmentIdList.data;
    if (district) {
      shipmentData[index].shipmentDestinationDistrict = new DistrictClient({
        id: district.id,
        name: `${district.name}, ${district.cityName}`,
        code: district.code,
        cityCode: district.cityCode,
        type: district.type,
        vendorCode: district.vendorCode
      });
    }
  }

  async addShipmentId() {
    this.form.shipmentId = this.form.sourceScaned.toUpperCase();
    this.handleError();
    if (!this.validationPrefixShipment) {
      playNotification("error");
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Penambahan Shipment ID Gagal !",
          message: `Shipment ID <b>“${this.form.shipmentId}”</b> tidak bisa diproses karena
          ${this.messageShipment}`,
          textSuccess: "Kembali",
          onSubmit: () => MainAppController.closeMessageModal(),
          image: "circle-error",
          idButtonSubmit: "instant_booking_pop_up_button_error_add_shipment"
        })
      );
      return;
    }
    const shipmentId = this.form.shipmentId;
    if (shipmentId.startsWith("TKP01")) {
      const shipment: any = await BookingController.getShipmentDetailFromMiddleware(
        {
          ticketCode: shipmentId
        }
      );

      if (shipment) {
        const origin = LocationController.districtDetail;
        const shipmentData = BookingController.shipmentIdList.data;
        const newShipmentData = [];
        let i = 0;
        for (const el of shipmentData) {
          try {
            const district = await LocationController.getDistrictClient({
              id: el.shipmentDestinationCityKR,
              args: new DistrictClientRequest({
                clientId: this.clientIdForCourierRecommendation
              })
            });
            this.setDistrict(district, i);
            newShipmentData.push(
              new ShipmentId({
                ...el,
                shipmentChargeableWeight: this.convertChargeableWeightKr(
                  el.shipmentGoodDetail
                ),
                shipmentSttClientId: this.clientIdForCourierRecommendation
              })
            );
          } catch (error) {
            newShipmentData.push(
              new ShipmentId({
                ...el,
                shipmentChargeableWeight: this.convertChargeableWeightKr(
                  el.shipmentGoodDetail
                ),
                shipmentSttClientId: this.clientIdForCourierRecommendation
              })
            );
          }
          i++;
        }

        BookingController.setShipmentIdList(
          new ShipmentIdList({
            pagination: new Pagination(1, 99),
            data: newShipmentData
          })
        );

        const data = BookingController.shipmentIdList.data;
        this.addShipmentToBook(data, "kr");
      }
    } else {
      await InstantBookingController.getShipmentIdList({
        id: shipmentId,
        limit: 100,
        page: 1,
        callback: this.addShipmentToBook
      });
    }
  }

  convertChargeableWeightKr(chws: Array<any>) {
    let chw = 0;
    chw = chws.reduce((a, b) => a + b.shipment_goods_weight, 0);
    return chw;
  }

  get messageShipment() {
    if (this.form.shipmentId.startsWith("DOR")) {
      return "shipment id tidak diperbolehkan bookimg melalui instant booking";
    }
    if (
      this.form.shipmentId.startsWith("AP") ||
      this.form.shipmentId.startsWith("AS")
    ) {
      return "pengiriman berupa Shipment Favorite.";
    }
    return "";
  }

  get validationPrefixShipment() {
    const shipmentId = this.form.shipmentId;
    return !(
      shipmentId.slice(0, 2) === "AP" || shipmentId.slice(0, 2) === "AS"
    );
  }

  addShipmentToBook(data: any, type = "non-kr") {
    if (data.length > 0) {
      for (const row of data) {
        this.onProcessBook(row, type);
      }

      return;
    }
    playNotification("error");
    const msg =
      data.length === 0 ? "tidak dikenal" : "alamat tujuan lebih dari satu.";
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: "Penambahan Shipment ID Gagal !",
        message: `Shipment ID <b>“${this.form.shipmentId}”</b> tidak bisa diproses karena ${msg}`,
        textSuccess: "Kembali",
        onSubmit: () => MainAppController.closeMessageModal(),
        image: "circle-error",
        idButtonSubmit: "instant_booking_pop_up_button_error_address"
      })
    );
  }

  async onProcessBook(detail: ShipmentId, type = "non-kr") {
    const indexCurrentShipment = this.sttListAfterScaned.findIndex(
      (data: ShipmentId) => {
        return (
          data.shipmentAlgoId === detail.shipmentAlgoId &&
          data.statusShipment !== "failed"
        );
      }
    );
    const senderData =
      !detail.shipmentSenderName ||
      !detail.shipmentSenderAddress ||
      !detail.shipmentSenderPhone;
    const receiverData =
      !detail.shipmentRecipientName ||
      !detail.shipmentRecipientAddress ||
      !detail.shipmentRecipientPhone;
    const destinationDistrictData =
      detail.shipmentDestinationDistrict &&
      detail.shipmentDestinationDistrict.id === 0;
    const detailProduct = !detail.shipmentProduct;
    const shipmentGoodDetail =
      detail.shipmentGoodDetail.length > 0 &&
      (type === "non-kr"
        ? this.checkPieceData(detail.shipmentGoodDetail)
        : this.checkPieceDataKr(detail.shipmentGoodDetail));
    if (indexCurrentShipment > -1) {
      playNotification("error");
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Penambahan Shipment ID Gagal !",
          message: `No. Shipment ID <b>“${this.form.shipmentId}”</b> sudah di input`,
          textSuccess: "Kembali",
          onSubmit: () => MainAppController.closeMessageModal(),
          image: "circle-error",
          idButtonSubmit: "instant_booking_pop_up_button_error_duplicate_id"
        })
      );
      return;
    }
    if (detail.STT) {
      playNotification("error");
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Penambahan Shipment ID Gagal !",
          message: `Shipment ID <b>“${this.form.shipmentId}”</b> tidak bisa diproses karena sudah berstatus booking`,
          textSuccess: "Kembali",
          onSubmit: () => MainAppController.closeMessageModal(),
          image: "circle-error",
          idButtonSubmit: "instant_booking_pop_up_button_error_status_booking"
        })
      );
      return;
    }

    if (
      senderData ||
      receiverData ||
      destinationDistrictData ||
      detailProduct
    ) {
      playNotification("error");
      const msg = destinationDistrictData
        ? "tidak bisa diproses karena alamat atau Kota/Kecamatan tujuan tidak ditemukan"
        : "tidak bisa diproses karena terdapat data mandatory yang kosong atau tidak ada";
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Penambahan Shipment ID Gagal !",
          message: `Shipment ID <b>“${this.form.shipmentId}”</b> ${msg}`,
          textSuccess: "Kembali",
          onSubmit: () => MainAppController.closeMessageModal(),
          image: "circle-error",
          idButtonSubmit: "instant_booking_pop_up_button_error_data_mandatory"
        })
      );
      return;
    }
    if (!shipmentGoodDetail) {
      playNotification("error");
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Penambahan Shipment ID Gagal !",
          message: `Shipment ID <b>“${this.form.shipmentId}”</b> tidak bisa diproses karena barang kosong atau tidak ada`,
          textSuccess: "Kembali",
          onSubmit: () => MainAppController.closeMessageModal(),
          image: "circle-error",
          idButtonSubmit: "instant_booking_pop_up_button_error_empty_goods"
        })
      );
      return;
    }
    if (detail.shipmentProduct.toLowerCase() === "jumbopack" && !this.dataProfile.isPartnerPcu) {
      playNotification("error");
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Penambahan Shipment ID Gagal !",
          message: `Shipment ID <b>“${this.form.shipmentId}”</b> hanya bisa diproses oleh POS PCU karena pengiriman menggunakan product Jumbopack.`,
          textSuccess: "Kembali",
          onSubmit: () => MainAppController.closeMessageModal(),
          image: "circle-error",
          idButtonSubmit: "instant_booking_pop_up_button_error_partner_not_pcu"
        })
      );
      return;
    }
    detail.postalCodeDestination = await this.validateForInterPack(detail);
    this.processDecision(type, detail);
  }

  async validateForInterPack(detail: ShipmentId) {
    if (detail.shipmentProduct === "INTERPACK") {
      MainAppController.showLoading();
      const listZipCodes = await this.fetchOriginListZipCode(
        detail.shipmentDestinationDistrict.id
      );
      MainAppController.closeLoading();
      if (!listZipCodes.length) {
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            title: "Penambahan Shipment ID Gagal !",
            message: `Shipment ID <b>“${this.form.shipmentId}”</b> tidak bisa diproses karena terdapat data mandatory yang kosong atau tidak ada`,
            textSuccess: "Kembali",
            onSubmit: () => MainAppController.closeMessageModal(),
            image: "circle-error",
            idButtonSubmit: "instant_booking_pop_up_button_error_data_mandatory"
          })
        );
        return "";
      }
      return listZipCodes[0];
    }

    return "";
  }

  processDecision(type: string, detail: ShipmentId) {
    playNotification("success");
    this.removeFailedProcessAgain(detail);
    this.storeData(type, [detail]);
    this.form.shipmentId = "";
    this.form.sourceScaned = "";
  }

  removeFailedProcessAgain(detail: ShipmentId) {
    const indexSttFailed = this.sttListAfterScaned.findIndex(
      (data: ShipmentId) =>
        data.shipmentAlgoId === detail.shipmentAlgoId &&
        data.statusShipment === "failed"
    );
    if (indexSttFailed > -1) {
      this.sttListAfterScaned.splice(indexSttFailed, 1);
    }
  }

  async fetchOriginListZipCode(idDistrict: number) {
    const responseDistrictData = await LocationController.getDetailDistrict({
      id: idDistrict,
      notSaveDetail: true
    });
    return responseDistrictData.originListZipCode;
  }

  checkPieceData(data: any) {
    const errorPieces: Array<any> = [];
    for (const el of data) {
      if (
        el.shipment_goods_weight !== 0 &&
        el.shipment_goods_length !== 0 &&
        el.shipment_goods_width !== 0 &&
        el.shipment_goods_height !== 0
      ) {
        errorPieces.push(true);
      } else {
        errorPieces.push(false);
      }
    }
    const checkPieces = errorPieces.every((item: any) => item);
    return !!checkPieces;
  }

  checkPieceDataKr(data: any) {
    const errorPieces: Array<any> = [];
    for (const el of data) {
      if (el.shipment_goods_weight !== 0) {
        errorPieces.push(true);
      } else {
        errorPieces.push(false);
      }
    }
    const checkPieces = errorPieces.every((item: any) => item);
    return !!checkPieces;
  }

  storeData(type: string, data: ShipmentId[]) {
    data.forEach(async (item: ShipmentId) => {
      this.unixId++;
      this.sttListAfterScaned.push({
        ...item,
        unixInstantBookId: this.unixId,
        statusShipment: "inProgress"
      });

      if (type === "kr") {
        this.bookingShipmentCorporate(item, this.unixId);
      } else {
        this.addInstantBooking(item, this.unixId);
      }
    });
    this.form.shipmentId = "";
  }

  get shipmentIdList() {
    return BookingController.shipmentIdList.data;
  }

  goBack() {
    this.$router.push(`/shipment/booking`);
  }

  unixId = 0;
  sttListAfterScaned: Array<any> = [];

  get totalSuccessBook() {
    return this.sttListAfterScaned.filter(
      (item: ShipmentId) => item.statusShipment === "success"
    );
  }

  get totalProgressBook() {
    return this.sttListAfterScaned.filter(
      (item: ShipmentId) => item.statusShipment === "inProgress"
    );
  }

  get totalFailedBook() {
    return this.sttListAfterScaned.filter(
      (item: ShipmentId) => item.statusShipment === "failed"
    );
  }

  get isSuccessList() {
    const successData = this.sttListAfterScaned.filter(
      (item: ShipmentId) => item.statusShipment === "success"
    );
    return successData.length;
  }

  isAddTerminated = false;
  newPath = "";
  answer = false;
  onOpenClosePage(to: any) {
    this.isAddTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isAddTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  get hasChanged() {
    return this.sttListAfterScaned.length > 0;
  }

  handlerCancelClose() {
    this.answer = false;
    this.isAddTerminated = false;
  }

  trackGTM(event: string) {
    GTMCommonEvent(event);
  }

  convertColorPrintStatus = (status: string) => {
    const statuses: any = {
      "Sedang Diproses": "text-yellow-lp-100",
      "Gagal Print": "text-red-lp-200",
      Sukses: "text-green-lp-200"
    };

    return statuses[status];
  };
}
