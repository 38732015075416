/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { ShipmentId, ShipmentIdList } from "@/domain/entities/Booking";
import { Pagination } from "@/domain/entities/Pagination";
import {
  DetailInstantBookingData,
  DetailInstantBookingListData,
  InstantBookingSession
} from "@/domain/entities/InstantBooking";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import { FAILED_ERROR, SPECIAL_ERROR } from "@/app/infrastructures/misc/Constants";
import { InstantBookingPresenter } from "../presenters/InstantBookingPresenter";
import { container } from "tsyringe";
import { CreateInstantBookingSessionApiRequest } from "@/data/payload/api/InstantBookingApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { UpdateSessionInstantBookingApiRequest } from "@/data/payload/api/InstantBookingApiRequest";
import {
  parsingErrorResponse,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { BookingPresenter } from "../presenters/BookingPresenter";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { AccountController } from "./AccountController";
export interface InstantBookingState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  shipmentIdList: ShipmentIdList;
  sttToBeBook: any;
}

@Module({ namespaced: true, dynamic: true, store, name: "instant_booking" })
class InstantBookingStore extends VuexModule implements InstantBookingState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public instantBookingDetail = new DetailInstantBookingData();
  public sttToBeBook: ShipmentId[] = [];
  public shipmentIdList = new ShipmentIdList({
    pagination: new Pagination(1, 10),
    data: []
  });

  @Action
  getDetail() {
    MainAppController.closeMessageModal();
    this.setLoading(true);
    const presenter = container.resolve(InstantBookingPresenter);
    return presenter
      .getDetail()
      .then(res => {
        this.setDetailData(res);
      })
      .catch(err => {
        this.setDetailData(new DetailInstantBookingData());
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public createSession(params: InstantBookingSession) {
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const presenter = container.resolve(InstantBookingPresenter);
    const payload = new CreateInstantBookingSessionApiRequest({
      posId: params.posId,
      posName: params.posName,
      originCityId: params.originCityId,
      originCityName: params.originCityName,
      totalShipmentId: params.totalShipmentId,
      stt: params.stt
    });
    return presenter
      .createInstantBookingSession(payload)
      .then((res: ResponsePayload) => res.data?.instant_booking_id)
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembuatan Session Gagal !", () =>
            this.createSession(params)
          )
        );
        return 0;
      })
      .finally(() => {
        MainAppController.closeMessageModal();
        MainAppController.closeLoading();
      });
  }

  @Action
  updateSession(params: { id: number; stt: DetailInstantBookingListData[] }) {
    MainAppController.showLoading();
    const presenter = container.resolve(InstantBookingPresenter);
    return presenter
      .updateSession(
        new UpdateSessionInstantBookingApiRequest({
          isFinish: false,
          instantBookingId: params.id,
          stt: params.stt
        })
      )
      .then(() => true)
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Update Instant Booking Gagal !", () =>
            this.finishSessionInstantBooking()
          )
        );
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  handleError() {
    this.isError = false;
    this.errorCause = "";
  }

  @Mutation
  setDetailData(val: DetailInstantBookingData) {
    this.instantBookingDetail = val;
  }

  @Action
  finishSessionInstantBooking() {
    MainAppController.showLoading();
    const presenter = container.resolve(InstantBookingPresenter);
    return presenter
      .updateSession(
        new UpdateSessionInstantBookingApiRequest({
          isFinish: true,
          instantBookingId: this.instantBookingDetail.ibId
        })
      )
      .then(() => {
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Update Instant Booking Gagal !", () =>
            this.finishSessionInstantBooking()
          )
        );
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public getShipmentIdList(params: {
    id: string;
    limit: number;
    page: number;
    callback: any;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const posCity = AccountController.accountData.account_type_detail.cityCode
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .getShipmentIdList(params.id, params.limit, params.page)
      .then((res: ShipmentIdList) => {
        const validationOriginDistrict = res.data[0].shipmentOriginCity !== posCity || !res.data[0].shipmentOriginCity
        const validationBukaSend = params.id.startsWith("B1") || params.id.startsWith("B2")
        if (params.id.startsWith("ACR") || params.id.startsWith("CCR")) {
          playNotification("error");
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              title: "Shipment ID CODREJ",
              message: `Silahkan update STT pengiriman ke <strong>Custom Status</strong>
              <strong>RTS/RTSHQ</strong> melalui akun <strong>konsolidator/sub 
              konsolidator</strong> untuk generate STT pengembalian`,
              type: SPECIAL_ERROR
            })
          );
        }
         else if (validationBukaSend && validationOriginDistrict) {
          playNotification("error");
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              title: "Booking Gagal",
              message: `Kota asal shipment id harus sesuai dengan 
              wilayah kota POS Anda.`,
              type: FAILED_ERROR
            })
          );
        } 
        else {
          params.callback(res.data);
        }
      })
      .catch((error: any) => {
        this.setShipmentIdList(
          new ShipmentIdList({ pagination: new Pagination(1, 10), data: [] })
        );
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Penambahan Shipment ID Gagal !", () =>
            this.getShipmentIdList(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  public setSttToBeBook(data: any) {
    this.sttToBeBook.push(data);
    storeDatatoLocalStorage("instant-booking-list", this.sttToBeBook);
  }

  @Mutation
  public setInstantBookingList(data: any) {
    this.sttToBeBook = data;
  }

  @Mutation
  public renewPrefixList(data: any) {
    this.sttToBeBook = data;
    storeDatatoLocalStorage("instant-booking-list", this.sttToBeBook);
  }

  @Mutation
  setShipmentIdList(data: ShipmentIdList) {
    this.shipmentIdList = data;
  }
}

export const InstantBookingController = getModule(InstantBookingStore);
